import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneSquare, faEnvelope, faCalendarAlt, faSmile } from "@fortawesome/free-solid-svg-icons"
import {faFacebook, faViber, faInstagram } from "@fortawesome/free-brands-svg-icons";

const Infobar = () => (
  <div className="infobar">
    <div className="container container-sm-fluid h-100">
      <div className="row h-100">
        <div className="col-6 col-md-3 align-self-center">
          <div className="align-middle">
            `<FontAwesomeIcon icon={faCalendarAlt} />` Понеділок - П'ятниця
          </div>
          <div className="align-middle">
            <FontAwesomeIcon icon={faCalendarAlt} /> 9:00 - 17:00
          </div>
        </div>
        <div className="col-6 col-md-3 align-self-center">
          <div className="align-middle">
            <FontAwesomeIcon icon={faPhoneSquare} /> <a href="tel:+38-073-016-08-19">+38 (073) 016-08-19</a>
          </div>
          <div className="align-middle">
            <FontAwesomeIcon icon={faPhoneSquare} /> <a href="tel:+38-068-518-63-18">+38 (068) 518-63-18</a>
          </div>
        </div>
        <div className="col-6 col-md-3 align-self-center">
          <div className="align-middle">
            <FontAwesomeIcon icon={faViber} /> <a href="tel:+38-0472-38-52-54"><b>Viber</b> +38 (068) 518-63-18</a>
          </div>
          <div className="align-middle">
            <FontAwesomeIcon icon={faEnvelope} /> <a href="mailto:medlaser.ck.ua@gmail.com">medlaser.ck.ua@gmail.com</a>
          </div>
        </div>
        <div className="col-6 col-md-3 align-self-center">
          <div className="align-middle">
            <a className="fb" href="https://www.facebook.com/groups/321245798956405" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faFacebook} /> Facebook &nbsp; &nbsp;
            </a>
            <a className="fb" href="https://www.instagram.com/laser_academy_che/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faInstagram} /> Instagram &nbsp;
            </a>
          </div>
          <div className="align-middle">
            <a href="http://www.fotonikaplus.com.ua/" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faSmile} /> Партнер «Фотоніка Плюс»
              {/*<img className="infobar-img" src="http://www.fotonikaplus.com.ua/images/design/logo.png" alt="ЧП «Фотоника Плюс»"/>*/}
          </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Infobar;
