import * as React from "react";

const Footer = () => (
  <section className="footer">
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
          <p className="h6">©2024 Академія Лазерної Медицини. Всі права захищено.</p>
        </div>
      </div>
  </div>
  </section>
);

export default Footer;