import * as React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import logo from "../../assets/images/logo_new2.svg"
import {getImageAltText} from "../../utils/common";

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "nav-link active" } : {className: "nav-link"}
}

const ExactNavLink = props => (
  <Link getProps={isActive} {...props} />
)

const Navbar = ({ siteTitle }) => {
  return (
    <nav className="navbar navbar-expand-md fixed-top">
      <div className="container">
        <Link to="/" className="navbar-brand gap-2 d-flex align-items-center" href="#">
          <img src={logo} alt={getImageAltText()} width="40" height="40"
               className="d-inline-block align-middle pb-2" />
          {' '}{siteTitle}
          </Link>


        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main-navbar"
                aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
          <FontAwesomeIcon icon={faBars} />
        </button>

        <div className="collapse navbar-collapse" id="main-navbar">
          <ul className="navbar-nav m-auto">
            <li className="nav-item">
              <ExactNavLink
                to="/"
              >
                Головна
              </ExactNavLink>
            </li>
            <li className="nav-item">
              <ExactNavLink
                to="/services"
              >
                Послуги
              </ExactNavLink>
            </li>
            {/*<li className="nav-item">*/}
            {/*  <ExactNavLink*/}
            {/*    to="/specialists"*/}
            {/*  >*/}
            {/*    Колектив*/}
            {/*  </ExactNavLink>*/}
            {/*</li>*/}
            <li className="nav-item">
              <ExactNavLink
                to="/feedback"
              >
                Відгуки
              </ExactNavLink>
            </li>
            <li className="nav-item">
              <ExactNavLink
                to="/contacts"
              >
                Контакти
              </ExactNavLink>
            </li>
          </ul>

          <button
            className="navbar-btn btn btn-sm fancy-button bg-gradient3 pop-onhover ml-auto wave in float-end"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#Modal"
          >
            <span>Передзвоніть мені</span>
          </button>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
